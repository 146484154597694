import styled from "@emotion/styled"
import mq from "@styles/mq"
import theme from "@styles/theme"
import React from "react"
import { PopupContent } from "semantic-ui-react"

export const ListingItemPopupContent = styled(PopupContent)`
  padding: 20px 0 15px 0;
`

export const ListingItemImage = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
  padding: 15px;
  float: left;
`
export const ListingItemImageContainer = styled.div`
  ${mq({
    width: ["100%", "100%", "160px"],
    height: ["100%", "100%", "160px"],
    paddingBottom: ["30px", "30px", "10px"],
    marginBottom: ["30px", "30px", "10px"],
  })}
  border-radius: 5px;
  background-color: ${theme.brand.colors.grey};
`
export const ListingItemDetailStatus = styled(({ status, ...others }) => (
  <div {...others} />
))`
  float: left;
  font-size: 14px;
  margin: 10px 30px 10px 0;
  padding: 6px 14px 6px 14px;
  height: 30px;
  border-radius: 5px;
  color: ${theme.brand.colors.white};
  font-weight: bold;
  background-color: ${props =>
    props.status == "Active"
      ? `${theme.brand.colors.green}`
      : props.status == "Expired"
      ? `${theme.brand.colors.red}`
      : props.status == "Expiring"
      ? `${theme.brand.colors.orange}`
      : `${theme.brand.colors.blue}`};
`
export const ListingItemDetailHeader = styled.div`
  border-bottom: 2px solid #eeeeee;
  display: flex;
`
export const ListingItemDetailHeaderTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
  color: black;
  float: left;
`
export const ListingItemDetailHeaderPricing = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: ${theme.brand.colors.green};
  margin-left: auto;
`

export const ListingItemDetailFooter = styled.div`
  display: flex;
`
export const ListingItemDetailExpiredRenew = styled.div`
  margin-left: auto;
  padding: 19px 0px 0px 26px;
`
export const ListingItemDetailExpireDate = styled.div`
  padding-top: 56px;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.brand.colors.darkGrey};
`

export const ListingItemDetailContent = styled.div`
  display: flex;
  border-bottom: 2px solid #eeeeee;
`
export const ListingItemDetailContainer = styled.div`
  padding: 0 0 0 30px;
  width: 100%;
`
export const ListingItemDetailAttributeValue = styled.div`
  font-size: 16px;
  line-height: 22px;
  font-weight: bold;
  color: ${theme.brand.colors.black};
`
export const ListingItemDetailAttributes = styled.div`
  ${mq({
    display: ["none", "none", "block"],
  })}
  padding: 12px 30px 12px 0;
`
export const ListingItemDetailAttributeName = styled.div`
  font-size: 12px;
  line-height: 16px;
  font-weight: bold;
  color: ${theme.brand.colors.darkGrey};
`
export const ListingItemActionControls = styled.div`
  display: flex;
  margin-left: auto;
`

export const ListingItemActionControl = styled.div`
  height: 100%;
  padding: 16px 8px 16px 8px;
  cursor: pointer;
`

export const ListingItemCard = styled.div`
  ${mq({
    margin: [
      "10px 10px 10px 10px",
      "30px 30px 30px 30px",
      "30px 30px 30px 30px",
    ],
    flexDirection: ["column", "column", "row"],
  })}
  border-radius: 8px;
  display: flex;
  background-color: ${theme.brand.colors.white};
  box-shadow: 0px 6px 16px #0000001f;
  padding: 30px;
`
