import { ListingSummaryType } from "@utils/ListingTypes"
import moment from "moment"
import React from "react"
import {
  ListingSumaryContainer,
  ListingSummaryPlateImage,
  ListingSumaryTitleContainer,
  ListingSummaryTitle,
  ListingSummaryContent,
  ListingSummaryPricing,
  ListingSummaryPricingTitle,
  ListingSummaryPricingPrice,
  ListingSummaryDates,
  ListingSummaryDateTitle,
  ListingSummaryDateValue,
  ListingSummaryFooter,
  ListingSummaryTotal,
  ListingSummaryTotalCost,
} from "./NewListing.styles"

type SummaryProps = {
  type: string
  combination?: string
  pricing: number
  startDate: Date
  expiryDate: Date
}

const ListingSummary = (props: SummaryProps) => {
  const imgUrl = props.combination
    ? `https://api.kiwiplates.nz/api//image/68/${props.combination}/4/4/max/400`
    : ""
  return (
    <ListingSumaryContainer>
      <ListingSumaryTitleContainer>
        <ListingSummaryTitle>
          {props.type == "New" ? "Listing Summary" : "Listing Confirmation"}
        </ListingSummaryTitle>
      </ListingSumaryTitleContainer>
      <ListingSummaryContent>
        {props.combination && <ListingSummaryPlateImage src={imgUrl} />}
        <ListingSummaryPricing>
          <ListingSummaryPricingTitle>
            New Marketplace Listing
          </ListingSummaryPricingTitle>
          <ListingSummaryPricingPrice>
            ${props.pricing}
          </ListingSummaryPricingPrice>
        </ListingSummaryPricing>
        <ListingSummaryDates>
          <ListingSummaryDateTitle>Listing Starts</ListingSummaryDateTitle>
          <ListingSummaryDateValue>
            {moment(props.startDate).format("DD MMMM, YYYY")}
          </ListingSummaryDateValue>
        </ListingSummaryDates>
        <ListingSummaryDates>
          <ListingSummaryDateTitle>Listing Expires</ListingSummaryDateTitle>
          <ListingSummaryDateValue>
            {moment(props.expiryDate).format("DD MMMM, YYYY")}
          </ListingSummaryDateValue>
        </ListingSummaryDates>
      </ListingSummaryContent>
      <ListingSummaryFooter>
        <ListingSummaryTotal>Total</ListingSummaryTotal>
        <ListingSummaryTotalCost>${props.pricing}</ListingSummaryTotalCost>
      </ListingSummaryFooter>
    </ListingSumaryContainer>
  )
}

export default ListingSummary
