import React, { useState } from "react"
import theme from "@styles/theme"
import { Form, Input } from "semantic-ui-react"
import { AuthFormLabel } from "@styles/Global.forms"
import { ButtonTypes } from "@utils/Constant"
import { KpButton } from "@elements/index"
import { ErrorContainer, MessageContainer } from "@styles/Global.styles"
import { Auth } from "aws-amplify"

const UpdatePassword = (props: { error?: string | undefined }) => {
  const { error } = props
  const [currentPassword, setCurrentPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [loading, setLoading] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [err, setErr] = useState("")

  const changePassword = async () => {
    setShowSuccess(false)
    setErr("")
    if (newPassword === confirmPassword) {
      await new Promise(r => setTimeout(r, 500))
      const user = await Auth.currentAuthenticatedUser()
      const result = await Auth.changePassword(
        user,
        currentPassword,
        newPassword
      )
      if (result == "SUCCESS") {
        setCurrentPassword("")
        setNewPassword("")
        setConfirmPassword("")
        setShowSuccess(true)
      } else {
        setShowSuccess(false)
      }
    } else {
      setErr("Your new password is not matched.")
    }
  }
  return (
    <Form>
      <Form.Field>
        <AuthFormLabel>CURRENT PASSWORD</AuthFormLabel>
        <Form.Input
          type="password"
          name="currentPassword"
          value={currentPassword}
          id="currentPassword"
          onChange={e => setCurrentPassword(e.target.value)}
        />
      </Form.Field>
      <Form.Field>
        <AuthFormLabel>NEW PASSWORD</AuthFormLabel>
        <Input
          type="password"
          name="newPassword"
          value={newPassword}
          id="newPassword"
          onChange={e => setNewPassword(e.target.value)}
        />
      </Form.Field>
      <Form.Field>
        <AuthFormLabel>CONFIRM NEW PASSWORD</AuthFormLabel>
        <Input
          type="password"
          name="confirmPassword"
          value={confirmPassword}
          id="confirmPassword"
          onChange={e => setConfirmPassword(e.target.value)}
        />
      </Form.Field>
      <Form.Field style={{ textAlign: "center", padding: "10px 0 10px 0" }}>
        <KpButton
          id="updateaccount-submit"
          color={theme.brand.colors.green}
          fullWidth="mobile tablet"
          buttonType={ButtonTypes.Primary}
          loading={loading}
          onClick={changePassword}
          type="submit"
        >
          {loading ? "Updating..." : "Change Password"}
        </KpButton>
      </Form.Field>
      {error && (
        <Form.Field style={{ textAlign: "center", padding: "10px 0 10px 0" }}>
          <ErrorContainer>{error}</ErrorContainer>
        </Form.Field>
      )}
      {err && (
        <Form.Field style={{ textAlign: "center", padding: "10px 0 10px 0" }}>
          <ErrorContainer>{err}</ErrorContainer>
        </Form.Field>
      )}
      {showSuccess && (
        <Form.Field style={{ textAlign: "center", padding: "10px 0 10px 0" }}>
          <MessageContainer>
            Your Password is Updated Successfully.
          </MessageContainer>
        </Form.Field>
      )}
    </Form>
  )
}
export default UpdatePassword
