import React, { useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby";
import { GenericBannerContainer } from "@components/GenericBannerContainer"
import { KpButton } from "@elements/index"
import { H4, Paragraph2 } from "@styles/Global.styles"
import theme from "@styles/theme"
import { ButtonTypes } from "@utils/Constant"
import { navigate } from "gatsby"
import { Grid } from "semantic-ui-react"
import ListingSummary from "./NewListing/ListingSummary"
import { ListingSection, NewListingTitle } from "./private.styles"
import { ListingDetailsType } from "@utils/ListingTypes";
import { MarketplaceListingService } from "@services/MarketplaceListingService";
import { buildDefaultImageUrl } from "@utils/Helpers";
const queryString = require('query-string');

const ListingPaymentConfirmationPage = (props: any) => {

  const [listingDetails, setListingDetails] = React.useState<ListingDetailsType>()

  const data = useStaticQuery(
    graphql`{
              site {
                siteMetadata {
                  apiUrl
                }
              }
              kontentItemMarketplacePaymentConfirmationPage {
                elements {
                  error_message {
                    value
                  }
                  heading {
                    value
                  }
                  success_message {
                    value
                  }
                }
              }
            }`,
  );

  const {elements} = data.kontentItemMarketplacePaymentConfirmationPage;
  const apiUrl = data.site.siteMetadata.apiUrl;
  const query = queryString.parse(props.location.search);

  useEffect(()=>{
    MarketplaceListingService.getListingDetails(apiUrl, query.listingId).then((data:any)=>{
      setListingDetails({
        ...data,
        imageUrls: data.imageUrls
          ? [
              buildDefaultImageUrl(apiUrl, data.combination),
              ...JSON.parse(data.imageUrls),
            ]
          : [buildDefaultImageUrl(apiUrl, data.combination)],
      });
    }).catch((error: any)=>{
      //TODO: redirect to listing not found page
      console.log(error);
    })
  },[]);

  const startDate = new Date();

  return (
    <GenericBannerContainer
      padding={{
        mobile: {
          top: 20,
          bottom: 40,
        },
        desktop: {
          top: 40,
          bottom: 60,
        },
      }}
      backgroundColor={theme.brand.colors.beige}
    >
      <Grid stackable reversed="mobile tablet vertically">
        <Grid.Column mobile={16} tablet={16} computer={10}>
          <NewListingTitle>{elements.heading.value}</NewListingTitle>
          {
            query.success && query.success === '1' &&
            <ListingSection>
              <Paragraph2 dangerouslySetInnerHTML={{ __html: elements.success_message.value }}></Paragraph2>
              <KpButton
                id="pa-vl"
                onClick={() => { navigate("/app/listings") }}
                buttonType={ButtonTypes.Primary}
                color={theme.brand.colors.blue}>
                View Listings
              </KpButton>
            </ListingSection>
          }
          {
            (!query.success || query.success === '0') &&
            <ListingSection>
              <Paragraph2 dangerouslySetInnerHTML={{ __html: elements.error_message.value }}></Paragraph2>
              <KpButton
                id="pa-vl"
                onClick={() => { navigate("/app/listings") }}
                buttonType={ButtonTypes.Primary}
                color={theme.brand.colors.blue}>
                View Listings
              </KpButton>
            </ListingSection>
          }
        </Grid.Column>
        {
          query.success && query.success === '1' && listingDetails &&
          <Grid.Column mobile={16} tablet={16} computer={5} floated="right">
            <ListingSummary
              type={listingDetails.listingType}
              combination={listingDetails.combination}
              pricing={listingDetails.isFeatured ? 59.95 : 39.95}
              startDate={new Date()}
              expiryDate={new Date(startDate.setDate(startDate.getDate() + 60))}
            />
          </Grid.Column>
        }
      </Grid>
    </GenericBannerContainer>
  )
}

export default ListingPaymentConfirmationPage
