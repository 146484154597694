import React, { useState } from "react"
import {
  NavListItem,
  NavListItemContent,
  NavListItemHeader,
  UserManageColumn2,
  UserManageColumn1,
  UserManageContent,
  UserManageContentArea,
  UserManageHeader,
  UserManageSectionHeader,
  UserManageWrapper,
} from "./private.styles"
import { Auth } from "aws-amplify"
import { useEffect } from "react"
import { Link } from "gatsby"
import { UserAccountWrapperContainer } from "@components/GenericBannerContainer"
import theme from "@styles/theme"
import { Divider, Grid, List } from "semantic-ui-react"
import { UserProfileType } from "@utils/AuthenticationTypes"
import UpdateAccount from "./UserProfile/UpdateAccount"
import UpdatePassword from "./UserProfile/UpdatePassword"
import { H2 } from "@styles/Global.styles"

import { SEO } from "@components/SEO"

const ProfilePage = () => {
  const [profile, setProfile] = useState<UserProfileType>({
    email: "",
    phone: "",
    name: "",
    sub: "",
  })
  const [isExternalProvider, setIsExternalProvider] = useState(false)
  const [images, setImages] = useState<string[]>([])
  const [err, setErr] = useState({
    accountUpdateError: "",
    updatePasswordError: "",
  })

  useEffect(() => {
    Auth.currentAuthenticatedUser().then(user => {
      const profile = {
        email: user.attributes.email,
        name: user.attributes.name,
        phone: user.attributes.phone_number,
        sub: user.attributes.sub,
      }
      const isExternalProvider =
        user.username.startsWith("facebook_") ||
        user.username.startsWith("google_")
      setIsExternalProvider(isExternalProvider)
      setProfile(profile)
    })
  }, [])

  const updateProfile = async () => {
    const user = await Auth.currentAuthenticatedUser()
    const updatedProfile =
      profile.phone !== undefined && profile.phone.trim() !== "+64"
        ? {
            phone_number: profile.phone,
            name: profile.name,
          }
        : {
            name: profile.name,
          }
    Auth.updateUserAttributes(user, updatedProfile)
      .then(res => {
        setErr({ ...err, accountUpdateError: "" })
      })
      .catch(err => {
        setErr({ ...err, accountUpdateError: err.message })
      })
  }

  return (
    <UserAccountWrapperContainer
      bgLeftColor={theme.brand.colors.blue}
      mobilePaddingTop={0}
      mobilePaddingBottom={0}
    >
      <SEO title="User Profile" />
      <Grid>
        <Grid.Row>
          <Grid.Column
            only="computer"
            computer={4}
            style={{
              float: "right",
              backgroundColor: theme.brand.colors.lightToGrey,
              minHeight: "calc(100vh - 180px)",
              paddingTop: "70px",
            }}
          >
            <List selection verticalAlign="middle" size="huge">
              <List.Item disabled>
                <List.Icon
                  name="user circle outline"
                  size="big"
                  verticalAlign="middle"
                  color="green"
                />
                <NavListItemHeader>Hi, {profile.name}</NavListItemHeader>
              </List.Item>
              <Divider />
              <NavListItem as={Link} to="/app/listings">
                <List.Icon
                  name="cart"
                  verticalAlign="middle"
                  size="big"
                  color="green"
                />
                <NavListItemContent>Marketplace Listings</NavListItemContent>
              </NavListItem>
              <NavListItem active>
                <List.Icon
                  name="cog"
                  verticalAlign="middle"
                  size="big"
                  color="green"
                />
                <NavListItemContent>My Details</NavListItemContent>
              </NavListItem>
            </List>
          </Grid.Column>
          <UserManageContentArea mobile={16} tablet={16} computer={12}>
            <UserManageHeader>
              <H2>My Details</H2>
            </UserManageHeader>
            <UserManageContent>
              <Grid stackable>
                <UserManageColumn1 computer={8} tablet={16} mobile={16}>
                  <UserManageWrapper>
                    <UserManageSectionHeader>
                      Update Account
                    </UserManageSectionHeader>
                    <UpdateAccount
                      profile={profile}
                      setProfile={setProfile}
                      updateProfile={updateProfile}
                      error={err.accountUpdateError}
                    />
                  </UserManageWrapper>
                </UserManageColumn1>
                {!isExternalProvider && (
                  <UserManageColumn2 computer={8} tablet={16} mobile={16}>
                    <UserManageWrapper>
                      <UserManageSectionHeader>
                        Change Password
                      </UserManageSectionHeader>
                      <UpdatePassword error={err.updatePasswordError} />
                    </UserManageWrapper>
                  </UserManageColumn2>
                )}
              </Grid>
            </UserManageContent>
          </UserManageContentArea>
        </Grid.Row>
      </Grid>
    </UserAccountWrapperContainer>
  )
}

export default ProfilePage
