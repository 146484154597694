import React from "react"
import { navigate } from "gatsby"
import { isLoggedIn } from "@auth/auth"
import { isBrowser } from "@utils/Helpers"
const PrivateRoute = ({ component: Component, location, ...rest }) => {
  if (location.pathname !== "/app/home") {
    if (!isLoggedIn() && isBrowser && location.pathname !== "/login") {
      console.log("navigating to login page as no login user")
      navigate("/login")
      return null
    }
  }

  return <Component location={location} {...rest} />
}

export default PrivateRoute
