import Auth from "@aws-amplify/auth"
import { KpButton } from "@elements/index"
import {
  ListingSection,
  PriceInputLabel,
  PriceInputText,
} from "@privateComponents/private.styles"
import { MarketplaceListingService } from "@services/MarketplaceListingService"
import {
  AuthFormLabel,
  DragTextInput,
  DragTextInputText,
  FileUploadInput,
  FileUploadItemWrapper,
  FileUploadWrapper,
  ImageContainer,
  ImagePreviewRemove,
  ImageUploadWrapper,
  KpFormInputErrorMessage,
} from "@styles/Global.forms"
import { H4 } from "@styles/Global.styles"
import theme from "@styles/theme"
import { EditListFormProps, UploadingImageType } from "@utils/ListingTypes"
import { navigate } from "gatsby-link"
import React, { Fragment, useEffect } from "react"
import { Grid, Form } from "semantic-ui-react"
import { Storage } from "aws-amplify"
import { S3BucketFileEditUrl } from "@utils/Constant"
import { matchTagRegex } from "@utils/Helpers"

const maplistingInfoToPhotos = (imageUrls: string[]): UploadingImageType[] => {
  const result = imageUrls.map(p => {
    const image: UploadingImageType = {
      name: p,
      key: p,
    }
    return image
  })
  return result
}

const { v4: uuidv4 } = require("uuid")
//TODO: add form validation
const EditListingForm = (props: EditListFormProps) => {
  const { formInfo, listingInfo, setListingInfo, errors } = props
  let mappedImageUrls = listingInfo.imageUrls.map(imageUrl => {
    return { name: imageUrl, key: imageUrl }
  })
  const [uploadingImages, setUploadingImages] = React.useState<any[]>(
    mappedImageUrls
  )

  useEffect(() => {
    mappedImageUrls = listingInfo.imageUrls.map(imageUrl => {
      return { name: imageUrl, key: imageUrl }
    })
    setUploadingImages(mappedImageUrls)
  }, [props])

  const cancel = () => {
    navigate("/app/listings")
    console.log("cancel update.")
  }

  const getFileKeyName = (key: string, fileType: string) => {
    let fileKeyName = key
    if (fileType === "image/jpeg") {
      fileKeyName = key + ".jpeg"
    } else if (fileType === "image/png") {
      fileKeyName = key + ".png"
    } else if (fileType === "image.gif") {
      fileKeyName = key + ".gif"
    }
    return fileKeyName
  }
  const upload = async (event: any) => {
    if (event.target.files && event.target.files.length > 0) {
      const newImages: UploadingImageType[] = []
      Array.from(event.target.files, async (file: any) => {
        // const fileId = uuidv4()
        // const fileServerKey = getFileKeyName(fileId, file.type)
        const image: UploadingImageType = {
          name: file.name,
        }
        console.log("adding images ....")
        newImages.push(image)

        setUploadingImages(prevState => [...prevState, image])
        const result: any = await Storage.put(file.name, file, {
          progressCallback(progress: any) {
            console.log(
              `${file.name} Uploaded: ${progress.loaded}/${progress.total}`
            )
          },
        })
        console.log("file " + result.key + " uploaded.")
        setUploadingImages(prevState => {
          const newimg = prevState.map(img =>
            img.name === file.name
              ? {
                  ...img,
                  ...{ key: result.key },
                  ...{ progress: { loaded: 100, total: 100 } },
                }
              : img
          )
          return newimg
        })
        setListingInfo({
          ...listingInfo,
          imageUrls: [...listingInfo.imageUrls, result.key],
        })
      })
    }
  }
  const deleteImage = async (imageKey?: string) => {
    if (imageKey) {
      console.log(imageKey)
      await Storage.remove(imageKey).then((data: any) => {
        setUploadingImages(prevState => {
          var newState = prevState.filter(s => s.key !== imageKey)
          return newState
        })
        setListingInfo({
          ...listingInfo,
          imageUrls: listingInfo.imageUrls.filter(s => s !== imageKey),
        })
      })
    }
  }
  return (
    <Fragment>
      <ListingSection>
        <H4>Plate Details</H4>
        <Form>
          <Grid>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <AuthFormLabel>Listing Type</AuthFormLabel>
              <div>
                {listingInfo.isFeatured
                  ? formInfo.featuredListingDetail.heading
                  : formInfo.standardListingDetail.heading}
              </div>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <AuthFormLabel>Plate Combination</AuthFormLabel>
              <div>{listingInfo.combination}</div>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <Form.Field>
                <AuthFormLabel>ASKING PRICE</AuthFormLabel>
                {errors.askingPrice && (
                  <KpFormInputErrorMessage>
                    {errors.askingPrice}
                  </KpFormInputErrorMessage>
                )}
                <Form.Input type="number">
                  <PriceInputLabel basic>$</PriceInputLabel>
                  <PriceInputText
                    name="price"
                    value={listingInfo.price}
                    id="price"
                    maxLength={9}
                    max={999999}
                    min={1}
                    onChange={(e: any) =>
                      {
                        setListingInfo({ ...listingInfo, price: e.target.value })
                        errors.askingPrice = ""
                        if(e.target.value <= 0){
                          errors.askingPrice = "Asking price is required."
                        }
                      }
                    }
                  />
                </Form.Input>
              </Form.Field>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <Form.Field>
                <AuthFormLabel>HAS THE PLATE BEEN MANUFACTURED?</AuthFormLabel>
                <Form.Select
                  options={formInfo.isManufactured}
                  value={listingInfo.isManufactured?.toString().toLocaleLowerCase()}
                  name="isManufactured"
                  id="isManufactured"
                  onChange={(e, data) =>
                    setListingInfo({
                      ...listingInfo,
                      isManufactured: data.value == "true",
                    })
                  }
                ></Form.Select>
              </Form.Field>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <Form.Field>
                <AuthFormLabel>Design Type</AuthFormLabel>
                <Form.Select
                  options={formInfo.plateDesigns}
                  value={listingInfo.plateDesignName?.toLocaleLowerCase()}
                  name="designType"
                  id="designType"
                  onChange={(e, data) =>
                    setListingInfo({
                      ...listingInfo,
                      plateDesignName: data.value,
                    })
                  }
                ></Form.Select>
              </Form.Field>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <Form.Field>
                <AuthFormLabel>PLATE CONDITION</AuthFormLabel>
                <Form.Select
                  options={formInfo.plateConditions}
                  value={listingInfo.condition?.toLocaleLowerCase()}
                  name="condition"
                  id="condition"
                  onChange={(e, data) =>
                    setListingInfo({ ...listingInfo, condition: data.value })
                  }
                ></Form.Select>
              </Form.Field>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <Form.Field>
                <AuthFormLabel>SHORT LISTING DESCRIPTION</AuthFormLabel>
                {errors.description && (
                  <KpFormInputErrorMessage>
                    {errors.description}
                  </KpFormInputErrorMessage>
                )}
                <Form.TextArea
                  name="description"
                  id="description"
                  value={listingInfo.description}
                  maxLength={350}
                  onChange={(e, data) =>
                    {                                            
                      if(!data.value || !matchTagRegex(data.value.toString())){
                        errors.description = ""
                      } else {
                        errors.description = "Enter valid characters"
                      }
                      setListingInfo({ ...listingInfo, description: data.value })
                    }   
                  }
                ></Form.TextArea>
              </Form.Field>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <AuthFormLabel>Upload Photos</AuthFormLabel>
              <FileUploadWrapper>
                {uploadingImages.map(img => {
                  if (img.key) {
                    const imgUrl = `${S3BucketFileEditUrl}/public/${img.key}`
                    return (
                      <FileUploadItemWrapper>
                        <ImagePreviewRemove
                          onClick={() => deleteImage(img.key)}
                        >
                          x
                        </ImagePreviewRemove>
                        <ImageContainer
                          src={imgUrl}
                          key={img.key}
                          alt={img.name}
                        />
                      </FileUploadItemWrapper>
                    )
                  } else {
                    return (
                      <FileUploadItemWrapper>
                        <div>{img.name}</div>
                        {img.progress ? (
                          <div>
                            uploading {img.progress.loaded} /{" "}
                            {img.progress.total}
                          </div>
                        ) : (
                          <div>loading...</div>
                        )}
                      </FileUploadItemWrapper>
                    )
                  }
                })}
                <ImageUploadWrapper>
                  <FileUploadInput
                    type="file"
                    name="editlistingimageupload"
                    accept="image/png, image/gif, image/jpeg"
                    multiple={true}
                    onChange={upload}
                  />
                  <DragTextInput>
                    <DragTextInputText>
                      Drag and drop images or select add images
                    </DragTextInputText>
                  </DragTextInput>
                </ImageUploadWrapper>
              </FileUploadWrapper>
            </Grid.Column>
          </Grid>
        </Form>
      </ListingSection>
    </Fragment>
  )
}

export default EditListingForm
