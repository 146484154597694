import React from "react"
import { Router } from "@reach/router"
import { Layout } from "@components/Layout"
import PrivateRoute from "@components/PrivateRoute/PrivateRoute"
import ProfilePage from "@privateComponents/user-profile"
import UserListingsPage from "@privateComponents/user-listings"
import ListingPaymentConfirmationPage from "@privateComponents/listing-payment-confirmation"
import UpdateListingPage from "@privateComponents/update-listing"
import NewListingPage from "@privateComponents/new-listing"
import Home from "@privateComponents/home"

const App = props => {
  return (
    <Layout version="authSimple" authEnabled={true}>
      <Router basepath="/app">
        <PrivateRoute component={ProfilePage} path="/profile" />
        <PrivateRoute component={UserListingsPage} path="/listings" />
        <PrivateRoute component={Home} path="/home" />
        <PrivateRoute component={NewListingPage} path="/new-listing" />
        <PrivateRoute
          component={ListingPaymentConfirmationPage}
          path="/listing-payment-confirmation"
        />
        <PrivateRoute component={UpdateListingPage} path="/update-listing" />
      </Router>
    </Layout>
  )
}

export default App
