import { KenticoChoice } from "@utils/KontentTypes";

interface SEOProps{
    seo__page_title:{
        value: string
    }
    seo__page_description:{
        value: string
    }
    seo__noindex:{
        value: KenticoChoice[]
    }
    seo__nofollow:{
        value: KenticoChoice[]
    }
    url:{
        value: string
    }
}

export const mapToSEO =(data:SEOProps) =>{
    const {seo__page_title,seo__page_description,seo__noindex,seo__nofollow, url} = data;
    return{
        title: seo__page_title.value,
        description: seo__page_description.value,
        noindex: seo__noindex.value[0].codename,
        nofollow: seo__nofollow.value[0].codename,
        url: url.value.startsWith('/') ? url.value : "/" + url.value
    }
}