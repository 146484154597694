import styled from "@emotion/styled"
import { Form } from "semantic-ui-react"
import React from "react"
import theme from "@styles/theme"
import mq from "@styles/mq"
import { H6 } from "@styles/Global.styles"

export const CombinationInputTextbox = styled(Form.Input)`
  color: green;
  background-color: grey;
  text-transform: uppercase;
  & > .input input {
    text-transform: uppercase;
  }
`

export const ListingSummaryPricing = styled.div`
  display: flex;
`
export const ListingSummaryPricingTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  color: ${theme.brand.colors.black};
`
export const ListingSummaryPricingPrice = styled.div`
  margin-left: auto;
  padding: 5px 0;
`
export const ListingSummaryDates = styled.div`
  display: flex;
  padding: 5px 0;
`
export const ListingSummaryDateTitle = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: ${theme.brand.colors.darkGrey};
`
export const ListingSummaryDateValue = styled.div`
  margin-left: auto;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.brand.colors.darkGrey};
`
export const ListingSummaryFooter = styled.div`
  display: flex;
  padding-top: 20px;
`
export const ListingSummaryTotal = styled.div`
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  color: ${theme.brand.colors.black};
`
export const ListingSummaryTotalCost = styled.div`
  margin-left: auto;
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  color: ${theme.brand.colors.black};
`

export const ListingSumaryContainer = styled.div`
  border-radius: 8px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  padding: 30px;
  display: block;
  flex-grow: 0 !important;
  ${mq({
    padding: ["20px", "20px", "30px"],
    position: ["relative", "relative", "fixed"],
  })}
`
export const ListingSumaryTitleContainer = styled.div`
  ${mq({
    marginBottom: ["10px", "30px", "30px"],
    paddingBottom: ["2px", "10px", "10px"],
  })}
  border-bottom: 2px solid #eeeeee;
`
export const ListingSummaryTitle = styled(H6)`
  font-weight: bold !important;
  display: inline-block;
  margin-bottom: 5px !important;
  ${mq({
    fontSize: ["15px", "16px", "18px"],
  })}
`
export const ListingSummaryContent = styled.div`
  border-bottom: 2px solid #eeeeee;
  padding-bottom: 30px;
`

export const ListingSummaryPlateImage = styled.img`
  object-fit: contain;
  width: 100%;
  padding-bottom: 20px;
`
