import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { EditListFormInfo } from "@utils/ListingTypes"
import EditListing from "./EditListing/EditListing"

const UpdateListingPage = (props: any) => {
  const listingId = props.location.state.listingId
  return (
    <StaticQuery
      query={graphql`
        {
          site {
            siteMetadata {
              apiUrl
            }
          }
          kontentItemListingTypeInfo {
            elements {
              standard_listing_period {
                value
              }
              term___conditions_link {
                value
              }
              type_description {
                value
              }
              featured_description {
                value
              }
              featured_price {
                value
              }
              featured_listing_period {
                value
              }
              standard_description {
                value
              }
              standard_price {
                value
              }
              is_manufactured {
                value {
                  name
                  codename
                }
              }
              plate_condition {
                value {
                  name
                  codename
                }
              }
              plate_designs {
                value {
                  codename
                  name
                }
              }
            }
          }
        }
      `}
      render={data => {
        const { elements } = data.kontentItemListingTypeInfo
        const isManufactured = elements.is_manufactured.value.map(
          (item: { codename: any; name: any }) => {
            return { value: item.codename, text: item.name }
          }
        )
        const plateConditions = elements.plate_condition.value.map(
          (item: { codename: any; name: any }) => {
            return { value: item.codename, text: item.name }
          }
        )
        const plateDesigns = elements.plate_designs.value.map(
          (item: { codename: any; name: any }) => {
            return { value: item.codename, text: item.name }
          }
        )
        const formData: EditListFormInfo = {
          listingTypeDescription: elements.type_description.value,
          termsUrl: elements.term___conditions_link.value,
          featuredListingDetail: {
            heading: "Featured Listing",
            description: elements.featured_description.value,
            period: elements.featured_listing_period.value,
            price: elements.featured_price.value,
          },
          standardListingDetail: {
            heading: "Standard Listing",
            description: elements.standard_description.value,
            period: elements.standard_listing_period.value,
            price: elements.standard_price.value,
          },
          isManufactured: isManufactured,
          plateConditions: plateConditions,
          plateDesigns: plateDesigns,
        }

        const apiUrl = data.site.siteMetadata.apiUrl
        const editProps = { formData: formData, apiUrl: apiUrl }
        return <EditListing editProps={editProps} listingId={listingId} />
      }}
    ></StaticQuery>
  )
}

export default UpdateListingPage
