import React, { Fragment } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Helmet from "react-helmet"

const ogDefaultImage = require("@assets/images/og-default.png")

interface SEOProps {
  title?: string
  description?: string
  image?: string
  imageDescription?: string
  prefix?: string
  type?: string
  url?: string
  extraTags?: string
  noindex?: string
  nofollow?: string
}

const SEO = ({
  title,
  description,
  image,
  imageDescription,
  prefix,
  type,
  url, //url slug (always start with /)
  extraTags,
  noindex,
  nofollow,
}: SEOProps) => {
  const data = useStaticQuery(
    graphql`
      {
        site {
          siteMetadata {
            lang
            locale
            siteUrl
            title
            twitterUsername
          }
        }
      }
    `
  )

  const {
    lang,
    locale,
    title: siteTitle,
    siteUrl,
    twitterUsername
  } = data.site.siteMetadata

  const imageUrl = `${image}?w=1080&h=1080&fit=crop&format=jpg`

  const htmlAttributes = {
    lang,
    prefix,
  }

  const robotsNoindex = noindex === "yes"
  const robotsNofollow = nofollow === "yes"

  let robots = ""
  let hasRobots = false
  if (robotsNofollow || robotsNoindex) {
    hasRobots = true
    if (robotsNoindex && robotsNofollow) robots = "nofollow noindex"
    else if (robotsNoindex) robots = "noindex"
    else robots = "nofollow"
  }
  if (siteUrl.toLowerCase() != "https://www.kiwiplates.nz"){
    hasRobots = true
    robots = "nofollow noindex"
  }

  return (
    <Fragment>
      <Helmet htmlAttributes={htmlAttributes} defer={false}>
        <script src="https://js.afterpay.com/afterpay-1.x.js" data-analytics-enabled async ></script>
        <title>{title}</title>
        <meta name="description" content={description} />
        {hasRobots && <meta name="robots" content={robots} />}
        <script>
          {`
              (function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:2682031,hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
              `}
        </script>
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content={twitterUsername} />
        <meta name="twitter:creator" content={twitterUsername} />

        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:type" content={type} />
        <meta property="og:url" content={`${siteUrl}${url}`} />
        <meta property="og:locale" content={locale} />
        <meta property="og:site_name" content={siteTitle} />
      </Helmet>
      {image && image.length ? (
        <Helmet>
          <meta property="og:image" content={imageUrl} />
          <meta property="og:image:alt" content={imageDescription} />
          <meta property="og:image:width" content="1080" />
          <meta property="og:image:height" content="1080" />
        </Helmet>
      ) : (
          <Helmet>
            <meta property="og:image" content={ogDefaultImage} />
            <meta property="og:image:alt" content="" />
            <meta property="og:image:width" content="1080" />
            <meta property="og:image:height" content="1080" />
          </Helmet>
        )}
      {extraTags && extraTags.length ? <Helmet>{extraTags}</Helmet> : null}
    </Fragment>
  )
}

SEO.defaultProps = {
  extraTags: [],
  image: "",
  imageDescription: "",
  prefix: "og: http://ogp.me/ns#",
  type: "website",
}

export default SEO
