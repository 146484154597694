import Auth from "@aws-amplify/auth"
import {
  DeletePopup,
  PopupContainer,
  PopupHeading,
} from "@components/ShoppingCart/ShoppingCart.styles"
import { KpButton } from "@elements/index"
import { MarketplaceListingService } from "@services/MarketplaceListingService"
import theme from "@styles/theme"
import { ButtonTypes } from "@utils/Constant"
import { ListingDetailsType } from "@utils/ListingTypes"
import { navigate } from "gatsby"
import moment from "moment"
import React from "react"
import { Grid, Icon } from "semantic-ui-react"
import {
  ListingItemCard,
  ListingItemImageContainer,
  ListingItemImage,
  ListingItemDetailContainer,
  ListingItemDetailHeader,
  ListingItemDetailHeaderTitle,
  ListingItemDetailHeaderPricing,
  ListingItemDetailContent,
  ListingItemDetailStatus,
  ListingItemDetailAttributes,
  ListingItemDetailAttributeName,
  ListingItemDetailAttributeValue,
  ListingItemActionControls,
  ListingItemActionControl,
  ListingItemPopupContent,
  ListingItemDetailFooter,
  ListingItemDetailExpireDate,
  ListingItemDetailExpiredRenew,
} from "./ListingDetailItem.styles"

type listingItemProps = {
  item: ListingDetailsType
  apiUrl: string
}

type listingItemState = {
  openDeleteDialog: boolean
  deleting: boolean
  loadingPayment: boolean
}

class ListingDetailItem extends React.Component<
  listingItemProps,
  listingItemState
> {
  constructor(props: listingItemProps) {
    super(props)
    this.state = {
      openDeleteDialog: false,
      deleting: false, // or your default width here
      loadingPayment: false,
    }
  }

  updateListing = () => {
    navigate("/app/update-listing/", {
      state: {
        listingId: this.props.item.listingId,
      },
    })
  }
  deleteListing = () => {
    Auth.currentSession().then(res => {
      this.setState({
        deleting: true,
      })
      let accessToken = res.getAccessToken()
      let jwt = accessToken.getJwtToken()

      MarketplaceListingService.deleteListing(
        this.props.apiUrl,
        jwt,
        this.props.item.listingId
      ).then(data => {
        if (data.data.Success) {
          location.reload()
        }
      })
    })
  }
  openDeleteDialog = (open: boolean) => {
    this.setState({
      openDeleteDialog: open,
    })
  }
  makePayment = (): any => {
    this.setState({
      loadingPayment: true,
    })
    Auth.currentSession().then(res => {
      let accessToken = res.getAccessToken()
      let jwt = accessToken.getJwtToken()

      const listingId = this.props.item.listingId

      MarketplaceListingService.getListingPaymentUrl(
        this.props.apiUrl,
        jwt,
        listingId,
        {
          ListingId: listingId,
          PaymentMethod: "CreditCard",
          ReturnUrl: `${this.props.apiUrl}/marketplace/payment/processPaymentResponse?paymentMethod=1`,
        }
      ).then((data: any) => {
        if (data != null && data != undefined) {
          this.setState({
            loadingPayment: false,
          })
          navigate(data)
        }
      })
    })
  }

  extendListing = (): any => {
    this.setState({
      loadingPayment: true,
    })
    Auth.currentSession().then(res => {
      let accessToken = res.getAccessToken()
      let jwt = accessToken.getJwtToken()

      const listingId = this.props.item.listingId

      MarketplaceListingService.getListingExtensionPaymentUrl(
        this.props.apiUrl,
        jwt,
        listingId,
        {
          ListingId: listingId,
          PaymentMethod: "CreditCard",
          ReturnUrl: `${this.props.apiUrl}/marketplace/payment/processPaymentResponse?paymentMethod=1&extension=1`,
        }
      ).then((data: any) => {
        if (data != null && data != undefined) {
          this.setState({
            loadingPayment: false,
          })
          navigate(data)
        }
      })
    })
  }

  render() {
    const { item, apiUrl } = this.props
    const imgUrl = `${apiUrl}/image/68/${item.combination}/4/4/max/400`
    const renewBtnId = `renew-${item.listingId}`
    return (
      <ListingItemCard>
        <ListingItemImageContainer>
          <ListingItemImage src={imgUrl} />
        </ListingItemImageContainer>
        <ListingItemDetailContainer>
          <ListingItemDetailHeader>
            <ListingItemDetailHeaderTitle>
              {item.combination}
            </ListingItemDetailHeaderTitle>
            <ListingItemDetailHeaderPricing>
              ${item.price}
            </ListingItemDetailHeaderPricing>
          </ListingItemDetailHeader>
          <ListingItemDetailContent>
            <ListingItemDetailStatus status={item.listingStatus}>
              {item.listingStatus.replace(/([A-Z])/g, " $1").trim()}
            </ListingItemDetailStatus>
            <ListingItemDetailAttributes>
              <ListingItemDetailAttributeName>
                Listing Type
              </ListingItemDetailAttributeName>
              <ListingItemDetailAttributeValue>
                {item.isFeatured ? "Featured" : "Standard"}
              </ListingItemDetailAttributeValue>
            </ListingItemDetailAttributes>
            <ListingItemDetailAttributes>
              <ListingItemDetailAttributeName>
                Design
              </ListingItemDetailAttributeName>
              <ListingItemDetailAttributeValue>
                {item.plateDesignName}
              </ListingItemDetailAttributeValue>
            </ListingItemDetailAttributes>
            <ListingItemDetailAttributes>
              <ListingItemDetailAttributeName>
                Condition
              </ListingItemDetailAttributeName>
              <ListingItemDetailAttributeValue>
                {item.condition}
              </ListingItemDetailAttributeValue>
            </ListingItemDetailAttributes>
            <ListingItemDetailAttributes>
              <ListingItemDetailAttributeName>
                Manufactured
              </ListingItemDetailAttributeName>
              <ListingItemDetailAttributeValue>
                {item.isManufactured ? "Yes" : "No"}
              </ListingItemDetailAttributeValue>
            </ListingItemDetailAttributes>

            <ListingItemActionControls>
              {(item.listingStatus == "Active" ||
                item.listingStatus == "AwaitingApproval" ||
                item.listingStatus == "PendingPayment") && (
                <ListingItemActionControl onClick={this.updateListing}>
                  <Icon size="large" color="green" name="pencil" />
                </ListingItemActionControl>
              )}
              {(item.listingStatus != "Deleted" && item.listingStatus != "AwaitingApproval") && (              
                <DeletePopup
                  trigger={
                    <ListingItemActionControl>
                      <Icon
                        size="large"
                        color="red"
                        name="trash alternate outline"
                      />
                    </ListingItemActionControl>
                  }
                  on="click"
                  onClose={() => this.openDeleteDialog(false)}
                  onOpen={() => this.openDeleteDialog(true)}
                  position="bottom right"
                  open={this.state.openDeleteDialog}
                  positionFixed={true}
                >
                  <PopupContainer>
                    <PopupHeading>Delete this listing?</PopupHeading>
                    <ListingItemPopupContent>
                      <Grid>
                        <Grid.Column width={8}>
                          <KpButton
                            id="scgo"
                            fullWidth="mobile tablet computer"
                            buttonType="primary"
                            loading={this.state.deleting}
                            color={theme.brand.colors.blue}
                            onClick={this.deleteListing}
                          >
                            CONFIRM
                          </KpButton>
                        </Grid.Column>
                        <Grid.Column width={8}>
                          <KpButton
                            id="scno"
                            fullWidth="mobile tablet computer"
                            buttonType="primary"
                            disabled={this.state.deleting}
                            color={theme.brand.colors.darkGrey}
                            onClick={() => this.openDeleteDialog(false)}
                          >
                            CANCEL
                          </KpButton>
                        </Grid.Column>
                      </Grid>
                    </ListingItemPopupContent>
                  </PopupContainer>
                </DeletePopup> 
              )}             
            </ListingItemActionControls>
          </ListingItemDetailContent>
          <ListingItemDetailFooter>
            {item.expiresOn !== undefined && item.expiresOn !== null && (
              <ListingItemDetailExpireDate>
                Listing expires {moment(item.expiresOn).format("DD MMM, YYYY")}
              </ListingItemDetailExpireDate>
            )}
            {item.listingStatus == "PendingPayment" && (
              <ListingItemDetailExpiredRenew>
                <KpButton
                  id={renewBtnId}
                  onClick={() => this.makePayment()}
                  loading={this.state.loadingPayment}
                  buttonType={ButtonTypes.Primary}
                  color={theme.brand.colors.blue}
                >
                  Make Payment
                </KpButton>
              </ListingItemDetailExpiredRenew>
            )}
            {item.listingStatus == "Expired" && (
              <ListingItemDetailExpiredRenew>
                <KpButton
                  id={renewBtnId}
                  buttonType={ButtonTypes.Primary}
                  color={theme.brand.colors.blue}
                  loading={this.state.loadingPayment}
                  onClick={() => this.extendListing()}
                >
                  Renew
                </KpButton>
              </ListingItemDetailExpiredRenew>
            )}
          </ListingItemDetailFooter>
        </ListingItemDetailContainer>
      </ListingItemCard>
    )
  }
}

export default ListingDetailItem
