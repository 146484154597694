// export type ListingStatus =  "Active" | "Expired" | "Awaiting Approval" | "Pending Payment"
// export type ListingType = "Featured" | "Standard"

export type ListingSummaryType = "New" | "Confirmation"

export type ListingDetailsType = {
  listingId: number
  combination: string
  price: number
  description?: string
  listingStatus: string
  plateDesignName: string
  isManufactured: boolean
  condition: string
  listingType: string
  isFeatured: boolean
  approved: boolean
  paymentStatus: number //1: authorized, 2: unpaid, 3: approved
  startDate?: Date
  expiresOn?: Date
  imageUrls?: string[]
  listingOwnerId: string
}

export type ListingShortType = {
  combination: string
  askingPrice: number
  listingId: number
  plateDesign: string
  startDate?: Date
}

export type ListingFormDataType = {
  type?: string //standard or featured listing
  combination: string
  isFeatured: boolean
  price: number
  isManufactured?: boolean
  plateDesignName?: string
  condition?: string
  description: string
  imageUrls: string[]
  listingStatus: string
}

export type EditListFormInfo = {
  standardListingDetail: {
    heading: string
    description: string
    price: number
    period: number
  }
  featuredListingDetail: {
    heading: string
    description: string
    price: number
    period: number
  }
  isManufactured: [
    {
      value: string
      text: string
    }
  ]
  plateConditions: [
    {
      value: string
      text: string
    }
  ]
  plateDesigns: [
    {
      value: string
      text: string
    }
  ]
  listingTypeDescription: string
  termsUrl: string
}

export type EditListFormProps = {
  formInfo: EditListFormInfo
  listingInfo: ListingFormDataType
  setListingInfo: Function
  errors: ListingValidationErrorType
}
export type EditListingProps = {
  formData: EditListFormInfo
  apiUrl: string
}
export type UploadingImageType = {
  name: string
  key?: string
  progress?: { loaded: number; total: number }
}
export type UploadFileType = {
  name: string
  id: string
  fileType: string
  key: string
  uploaded?: boolean
}
export type ListingValidationErrorType = {
  combination: string
  askingPrice: string
  description: string
}
export const initListingValidationErrorValue = {
  combination: "",
  askingPrice: "",
  description:""
}
