import { CheckoutSelectionCardButton, CheckoutSelectionCardIndicator } from "@components/CheckoutSelectionCard/CheckoutSelectionCard.styles";
import { ListingTypeCardContainer, ListingTypeCardHeading, ListingTypePricingSection, ListingTypePeriod } from "@privateComponents/private.styles";
import { H6, Paragraph2 } from "@styles/Global.styles";
import theme from "@styles/theme";
import React from "react";
import { Icon } from "semantic-ui-react";

type ListingTypeCardProps = {
    heading: string,
    description: string,
    price: number,
    period: number,
    onSelect: Function,
    isSelected: boolean
}
const ListingTypeCard = (props: ListingTypeCardProps) =>{
    const {heading, price, period, description, onSelect, isSelected} = props;
    return (
        <ListingTypeCardContainer className={isSelected ? 'selected' : ''}>
            <div>
                <ListingTypeCardHeading>{heading}</ListingTypeCardHeading>
                <ListingTypePricingSection>
                    <H6 color={theme.brand.colors.green}>
                        ${price}
                    </H6>
                    <ListingTypePeriod>
                        {period} Day Listing
                    </ListingTypePeriod>
                </ListingTypePricingSection>
                <Paragraph2 color='#707070'>
                    {description}
                </Paragraph2>
            </div>                
            <div>
                    {
                        isSelected &&
                        <CheckoutSelectionCardButton id="listing-csc-selected" buttonType='primary' color={theme.brand.colors.green}>SELECTED</CheckoutSelectionCardButton>
                    }
                    {
                        !isSelected && 
                        <CheckoutSelectionCardButton id="listing-csc-select" buttonType='secondary' color={theme.brand.colors.green} onClick={onSelect}>SELECT</CheckoutSelectionCardButton>
                    }
                </div>
                {
                    isSelected &&
                    <CheckoutSelectionCardIndicator>
                        <Icon name='check' size='small'/>
                    </CheckoutSelectionCardIndicator>
                }
        </ListingTypeCardContainer>
    )
}
export default ListingTypeCard