// The parking page to load user checked in data from local storage for social login only

import { isLoggedIn } from "@auth/auth"
import { navigate } from "gatsby-link"
import React, { useEffect } from "react"

import { SEO } from "@components/SEO"

const sleep = async (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms))
}
// The loading page after user LoginPage
const Home = () => {
  useEffect(() => {
    const redirect = async () => {
      await sleep(1000)
      const loggedIn = isLoggedIn()
      if (loggedIn) {
        navigate("/app/listings")
      } else {
        navigate("/login")
      }
    }
    redirect()
  }, [])

  return (
    <div>
      <SEO title="Redirecting..." />
      <div>Loading ...</div>
    </div>
  )
}
export default Home
