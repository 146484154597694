import React, { Fragment, useState } from "react"
import {
  NavListItem,
  NavListItemContent,
  NavListItemHeader,
  UserContentEmpty,
  UserManageContent,
  UserManageContentArea,
  UserManageHeader,
} from "./private.styles"
import { Auth } from "aws-amplify"
import { useEffect } from "react"
import { graphql, Link, StaticQuery } from "gatsby"
import { UserAccountWrapperContainer } from "@components/GenericBannerContainer"
import theme from "@styles/theme"
import { Divider, Grid, List } from "semantic-ui-react"
import { ButtonTypes } from "@utils/Constant"
import { KpButton } from "@elements/index"
import ListingDetailItem from "./UserListings/ListingDetailItem"
import { UserProfileType } from "@utils/AuthenticationTypes"
import { ListingDetailsType } from "@utils/ListingTypes"
import { MarketplaceListingService } from "@services/MarketplaceListingService"
import { H2, H6 } from "@styles/Global.styles"
import { LoadingIcon } from "@components/OrderConfirmation/OrderConfirmation.styles"

const UserListings = (props: { apiUrl: string }) => {
  const [profile, setProfile] = useState<UserProfileType>({
    email: "",
    name: "",
    phone: "",
    sub: "",
  })
  const [listings, setListings] = useState<ListingDetailsType[]>([])
  const [isLoading, setIsLoading] = useState<boolean>()
  useEffect(() => {
    Auth.currentAuthenticatedUser().then(user => {
      console.log("attributes:", user.attributes)
      const profile = {
        email: user.attributes.email,
        name: user.attributes.name,
        phone: user.attributes.phone_number,
        sub: user.sub,
      }
      setProfile(profile)

      setIsLoading(true)
      Auth.currentSession().then(res => {
        let accessToken = res.getAccessToken()
        let jwt = accessToken.getJwtToken()

        MarketplaceListingService.getUserListings(props.apiUrl, jwt).then(
          (data: ListingDetailsType[]) => {
            data = data.map((data: any) => {
              return {
                ...data,
                imageUrls: data.imageUrls ? JSON.parse(data.imageUrls) : [],
              }
            })
            setListings(data)
            setIsLoading(false)
          }
        )
      })
    })
  }, [])

  return (
    // <PrivatePageWrapper>
    <UserAccountWrapperContainer
      bgLeftColor={theme.brand.colors.blue}
      mobilePaddingTop={0}
      mobilePaddingBottom={0}
    >
      <Grid>
        <Grid.Row>
          <Grid.Column
            only="computer"
            computer={4}
            style={{
              float: "right",
              backgroundColor: theme.brand.colors.lightToGrey,
              minHeight: "calc(100vh - 180px)",
              paddingTop: "70px",
            }}
          >
            <List selection verticalAlign="middle" size="huge">
              <List.Item disabled>
                <List.Icon
                  name="user circle outline"
                  size="big"
                  verticalAlign="middle"
                  color="green"
                />
                <NavListItemHeader>Hi, {profile.name}</NavListItemHeader>
              </List.Item>
              <Divider />
              <NavListItem as={Link} to="/app/listings" active>
                <List.Icon
                  name="cart"
                  verticalAlign="middle"
                  size="big"
                  color="green"
                />
                <NavListItemContent>Marketplace Listings</NavListItemContent>
              </NavListItem>
              <NavListItem as={Link} to="/app/profile">
                <List.Icon
                  name="cog"
                  verticalAlign="middle"
                  size="big"
                  color="green"
                />
                <NavListItemContent>My Details</NavListItemContent>
              </NavListItem>
            </List>
          </Grid.Column>
          <UserManageContentArea mobile={16} tablet={16} computer={12}>
            <UserManageHeader>
              <Grid stackable>
                <Grid.Column computer={10} tablet={10} mobile={16}>
                  <H2>My Marketplace Listings</H2>
                </Grid.Column>
                <Grid.Column
                  computer={6}
                  tablet={6}
                  mobile={16}
                  textAlign="right"
                >
                  <KpButton
                    buttonType={ButtonTypes.Primary}
                    color={theme.brand.colors.blue}
                    fullWidth="mobile"
                    link="/app/new-listing"
                    id="add-listings"
                  >
                    + New Listing
                  </KpButton>
                </Grid.Column>
              </Grid>
            </UserManageHeader>
            {!isLoading && (
              <Fragment>
                {listings.length == 0 ? (
                  <UserContentEmpty>
                    <Grid style={{ margin: "auto" }}>
                      <Grid.Column textAlign="center" width={16}>
                        <H6>Your listings are empty.</H6>
                      </Grid.Column>
                    </Grid>
                  </UserContentEmpty>
                ) : (
                  <UserManageContent>
                    <Grid>
                      <Grid.Row columns={1}>
                        <Grid.Column>
                          {listings.map(item => {
                            return (
                              <ListingDetailItem
                                key={item.listingId}
                                item={item}
                                apiUrl={props.apiUrl}
                              />
                            )
                          })}
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </UserManageContent>
                )}
              </Fragment>
            )}
            {isLoading && (
              <UserContentEmpty>
                <Grid style={{ margin: "auto" }}>
                  <Grid.Column textAlign="center" width={16}>
                    <H6>
                      Loading Listings
                      <LoadingIcon name="circle notch" size="large" loading />
                    </H6>
                  </Grid.Column>
                </Grid>
              </UserContentEmpty>
            )}
          </UserManageContentArea>
        </Grid.Row>
      </Grid>
    </UserAccountWrapperContainer>
  )
}

const UserListingsPage = (props: any) => {
  return (
    <StaticQuery
      query={graphql`
        {
          site {
            siteMetadata {
              apiUrl
            }
          }
        }
      `}
      render={data => {
        const apiUrl = data.site.siteMetadata.apiUrl
        return <UserListings apiUrl={apiUrl} />
      }}
    />
  )
}

export default UserListingsPage
