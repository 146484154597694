import { KpButton } from "@elements/index"
import { AuthFormLabel } from "@styles/Global.forms"
import { ErrorContainer, MessageContainer } from "@styles/Global.styles"
import theme from "@styles/theme"
import { UserProfileType } from "@utils/AuthenticationTypes"
import { ButtonTypes, NameRegex } from "@utils/Constant"
import React from "react"
import { Form, Input } from "semantic-ui-react"

const UpdateAccount = (props: {
  profile: UserProfileType
  setProfile: Function
  updateProfile: Function
  error?: string | undefined
}) => {
  const { profile, setProfile, updateProfile, error } = props

  const [loading, setLoading] = React.useState(false)
  const [showSuccess, setShowSuccess] = React.useState(false)
  const saveProfile = async () => {
    setShowSuccess(false)
    setLoading(true)
    await new Promise(r => setTimeout(r, 500))
    await updateProfile()
    if (!error) {
      setShowSuccess(true)
    }
    setLoading(false)
  }
  return (
    <Form>
      <Form.Field>
        <AuthFormLabel>EMAIL</AuthFormLabel>
        <Input
          type="email"
          name="email"
          value={profile.email}
          id="email"
          disabled={true}
        />
      </Form.Field>
      <Form.Field>
        <AuthFormLabel>NAME</AuthFormLabel>
        <Form.Input
          type="text"
          name="name"
          value={profile.name}
          id="name"
          onChange={e => {
            if (NameRegex.test(e.target.value)){
              setProfile({ ...profile, name: e.target.value })
            } else {
              setShowSuccess(false)
            }            
          }
          }
        />
      </Form.Field>
      <Form.Field>
        <AuthFormLabel>PHONE</AuthFormLabel>
        <Input
          type="text"
          name="phone"
          label="+64"
          value={profile.phone !== undefined ? profile.phone.substring(3) : ""}
          id="phone"
          onChange={e =>
            setProfile({ ...profile, phone: "+64" + e.target.value })
          }
        />
      </Form.Field>
      <Form.Field style={{ textAlign: "center", padding: "10px 0 10px 0" }}>
        <KpButton
          id="updateaccount-submit"
          color={theme.brand.colors.green}
          fullWidth="mobile tablet"
          buttonType={ButtonTypes.Primary}
          onClick={saveProfile}
          loading={loading}
          type="submit"
        >
          {loading ? "Saving ..." : "Save"}
        </KpButton>
      </Form.Field>
      {error && (
        <Form.Field style={{ textAlign: "center", padding: "10px 0 10px 0" }}>
          <ErrorContainer>{error}</ErrorContainer>
        </Form.Field>
      )}
      {showSuccess && (
        <Form.Field style={{ textAlign: "center", padding: "10px 0 10px 0" }}>
          <MessageContainer>
            Your Profile is Updated Successfully.
          </MessageContainer>
        </Form.Field>
      )}
    </Form>
  )
}
export default UpdateAccount
