import Auth from "@aws-amplify/auth"
import { GenericBannerContainer } from "@components/GenericBannerContainer"
import { KpButton } from "@elements/index"
import { NewListingTitle } from "@privateComponents/private.styles"
import { MarketplaceListingService } from "@services/MarketplaceListingService"
import { ErrorContainer } from "@styles/Global.styles"
import theme from "@styles/theme"
import {
  ListingFormDataType,
  EditListingProps,
  ListingValidationErrorType,
  initListingValidationErrorValue,
} from "@utils/ListingTypes"
import { navigate } from "gatsby"
import moment from "moment"
import React from "react"
import { Form, Grid } from "semantic-ui-react"
import ListingSummary from "./ListingSummary"
import NewListingForm from "./NewListingForm"

const NewListing = (props: EditListingProps) => {
  const { formData, apiUrl } = props
  const [listingInfo, setListingInfo] = React.useState<ListingFormDataType>({
    combination: "",
    description: "",
    plateDesignName: formData.plateDesigns[0].value,
    condition: formData.plateConditions[0].value,
    isManufactured: formData.isManufactured[0].value == "yes" ? true : false,
    isFeatured: false,
    price: 0,
    imageUrls: [],
    listingStatus: "",
  })

  const [loadingPayment, setLoadingPayment] = React.useState<boolean>(false)
  const [entitlementCheck, setEntitlementCheck] = React.useState<boolean>(false)
  const [termsConditionCheck, setTermsConditionCheck] = React.useState<boolean>(
    false
  )
  const [validationCheck, setValidationCheck] = React.useState<boolean>(false)
  const [errors, setErrors] = React.useState<ListingValidationErrorType>(
    initListingValidationErrorValue
  )
  const listingStart = new Date()
  const expiryDate =
    listingInfo.type == "Featured"
      ? moment()
        .add(formData.standardListingDetail.period, "days")
        .toDate()
      : moment()
        .add(formData.featuredListingDetail.period, "days")
        .toDate()
  // console.log(listingInfo)

  const createListing = () => {
    setLoadingPayment(true)
    // <PrivatePageWrapper>
    Auth.currentSession().then(res => {
      let accessToken = res.getAccessToken()
      let jwt = accessToken.getJwtToken()

      MarketplaceListingService.createListing(apiUrl, jwt, {
        combination: listingInfo.combination.toUpperCase(),
        condition: listingInfo.condition,
        description: listingInfo.description,
        isFeatured: listingInfo.type === "Featured",
        plateDesignName: listingInfo.plateDesignName,
        price: listingInfo.price,
        isManufactured: listingInfo.isManufactured,
        imageUrls: JSON.stringify(listingInfo.imageUrls),
      }).then((data: any) => {
        if (data != null && data != undefined) {
          MarketplaceListingService.getListingPaymentUrl(apiUrl, jwt, data, {
            ListingId: data,
            PaymentMethod: "CreditCard",
            ReturnUrl: `${apiUrl}/marketplace/payment/processPaymentResponse?paymentMethod=1`,
          }).then((data: any) => {
            if (data != null && data != undefined) {
              setLoadingPayment(false)
              navigate(data)
            }
          })
        }
      })
    })
  }

  const goBack = () => {
    navigate("/app/listings")
  }
  const saveAndGoToPayment = () => {
    setErrors(initListingValidationErrorValue)
    setValidationCheck(false)
    if (listingInfo.combination.length == 0 || listingInfo.price <= 0 
      || errors.askingPrice.length > 0 || errors.combination.length > 0 || errors.description.length > 0) {
      if (listingInfo.combination.length == 0) {
        console.log(listingInfo.combination)
        setErrors({ ...errors, combination: "Combination is required." })
        return
      } 
      if (listingInfo.price <= 0) {
        console.log(listingInfo.price)
        setErrors({ ...errors, askingPrice: "Asking price is required." })
        return
      }      
    } else {
      if (entitlementCheck == false || termsConditionCheck == false) {
        setValidationCheck(true)
      } else {
        setValidationCheck(false)
        createListing()
      }
    }
  }
  return (
    <GenericBannerContainer
      padding={{
        mobile: {
          top: 20,
          bottom: 40,
        },
        desktop: {
          top: 40,
          bottom: 60,
        },
      }}
      backgroundColor={theme.brand.colors.beige}
    >
      <Grid stackable>
        <Grid.Column mobile={16} tablet={16} computer={10}>
          <NewListingTitle>New Marketplace Listing</NewListingTitle>
          <NewListingForm
            formInfo={formData}
            listingInfo={listingInfo}
            setListingInfo={setListingInfo}
            errors={errors}
          />
        </Grid.Column>
        <Grid.Column mobile={16} tablet={16} computer={5} floated="right">
          {listingInfo.combination !== "" && (
            <ListingSummary
              type="New"
              combination={listingInfo.combination}
              pricing={
                listingInfo.type == "Featured"
                  ? formData.featuredListingDetail.price
                  : formData.standardListingDetail.price
              }
              startDate={listingStart}
              expiryDate={expiryDate}
            />
          )}
        </Grid.Column>

        <Grid.Column mobile={16} tablet={16} computer={10} floated="left">
          <Form>
            <Grid fluid>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  <Form.Field>
                    <Form.Checkbox
                      name="entitlement"
                      id="entitlement"
                      label="I confirm that I hold the entitlement to this plate"
                      checked={entitlementCheck}
                      onClick={() => setEntitlementCheck(!entitlementCheck)}
                    ></Form.Checkbox>
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  <Form.Field>
                    <Form.Checkbox
                      name="tandc"
                      id="tandc"
                      label={{
                        children: (
                          <div>
                            I accept the &nbsp;
                            <a href={formData.termsUrl} target="_blank">
                              Marketplace Terms and Conditions.
                            </a>
                          </div>
                        ),
                      }}
                      checked={termsConditionCheck}
                      onClick={() =>
                        setTermsConditionCheck(!termsConditionCheck)
                      }
                    ></Form.Checkbox>
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
              {validationCheck &&
                (entitlementCheck == false || termsConditionCheck == false) && (
                  <Grid.Row>
                    <Grid.Column mobile={16} tablet={16} computer={16}>
                      {entitlementCheck == false ? (
                        <ErrorContainer>
                          Please confirm your entitlement to this plate.
                        </ErrorContainer>
                      ) : (
                          termsConditionCheck == false && (
                            <ErrorContainer>
                              Please accept the terms and conditions.
                            </ErrorContainer>
                          )
                        )}
                    </Grid.Column>
                  </Grid.Row>
                )}
              <Grid.Row verticalAlign="bottom">
                <Grid.Column width={16}>
                  <Grid reversed="mobile vertically">
                    <Grid.Column mobile={16} tablet={8} computer={8}>
                      <KpButton
                        id="pms-back"
                        fullWidth="mobile"
                        buttonType="secondary"
                        color={theme.brand.colors.darkGrey}
                        onClick={goBack}
                      >
                        BACK
                      </KpButton>
                    </Grid.Column>
                    <Grid.Column
                      mobile={16}
                      tablet={8}
                      computer={8}
                      textAlign="right"
                    >
                      <KpButton
                        id="nlisting-next"
                        fullWidth="mobile"
                        loading={loadingPayment}
                        buttonType="primary"
                        color={theme.brand.colors.blue}
                        onClick={saveAndGoToPayment}
                      >
                        GO TO PAYMENT
                      </KpButton>
                    </Grid.Column>
                  </Grid>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </Grid.Column>
      </Grid>
    </GenericBannerContainer>
  )
}

export default NewListing
